import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const ronakExpertisesData = allData.ronakExpertisesData;

const userData = {
    name: "Ronak Pipaliya",
    designation: "Project Manager",
    image: '../assets/img/ronak-pipaliya/ronak-pipaliya.png',
    tag: `ronak-pipaliya`,
    phones: ['(+91) 8000141985', '(+91) 7359349940'],
    // emails: ['ronak@vasundhara.io', 'ronak.vasundhara@gmail.com'],
    emails: ['ronak@vasundhara.io'],
    linkedin: 'https://in.linkedin.com/in/ronak-pipaliya-4839031a9',
    // facebook='https://www.facebook.com/ronak.pipaliya.92',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    description: [
        `Experienced Project Manager at Vasundhara Infotech, highly motivated with proven success in cross-functional project completion.`,
        `Interested in a developing game that people can enjoy.`,
        `In the past year, I have managed the development of many products (games) for different clients and handled data feed for the same project's implementation.`,
        `I am a team player with strong problem-solving skills, including analyzing complex issues and developing efficient solutions while seeing the big picture.`,
        `Knows how to motivate teams, with a passion for small details in every product and project. Have strong interpersonal and communication skills, which I use to lead my teams, customers, and business partners.`,
        `The ability to multitask and prioritize in a fast-paced organization according to the transverse of cost and time. Ability to work across various business functions and influence stakeholders.`,
        `Do the work in the Agile and water-flow model. And try to allocate the resources required to meet the project's goals and objectives in the best way.`,
        `Observe the client requirements and features specifications to set a roadmap and KPIs with the cooperation of front-end and back-end development and with the full support of QA teams.`,
        `I am a fast and self-learner in the Gaming field, and I love discovering and studying the new world of the game.`
    ]
}

function RonakPipaliya() {
    const [portFolio, setPortFolio] = useState([]);

    useEffect(() => {
        allApiData.aosinit();

        allApiData.allPortfolios(1, 'Game Development', 'app_type').then((data) => {
            setPortFolio(data.data);
        });
    }, [])

    return (
        <Layout footernone={false} padding={true}>
            <div className='main-ronak position-relative'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={false}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=game_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        typeWise='app_type'
                        isPagination={false}
                        portfolios={portFolio}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={ronakExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>

            </div>
        </Layout>
    )
}

export default RonakPipaliya